/* eslint-disable no-unused-vars */

/* eslint-disable linebreak-style */
import clsx from 'clsx';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import 'animate.css/animate.min.css';
import { ConnectedRouter } from 'connected-react-router';
import 'react-quill/dist/quill.snow.css'; // ES6
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import './App.scss';
import { WEB_TOKEN } from './constants/otherConstants';

import Login from './components/Auth/Login/Login';

import agent from './agent';
import AssignmentSubmission from './components/AssignmentSubmission/AssignmentSubmission';
import UserDetails from './components/AssignmentSubmission/UserDetails';

import CoursePlan from './components/Courses/CoursePlan';
import Courses from './components/Courses/Courses';
import CourseStudents from './components/Courses/CourseStudents';
import NavBar from './components/NavBar/NavBar';
import Questions from './components/Questions/Questions';

import { useSelector } from 'react-redux';
import { shiftStyles } from './common/commonUseStyles';
import ForgotPassword from './components/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword/ResetPassword';
import UserMessages from './components/UserMessages/UserMessages';
import { pathNames } from './constants/navigationPath';
import TaskQuestions from './components/TaskQuestions/TaskQuestions';
import Slots from './components/Slots/Slots';
import Appointments from './components/Appointments/Appointments';
import Teams from './components/Teams/Teams';
import AppointmentReport from './components/AppointmentReport/AppointmentReport';
import PreviousMockHistory from './components/PreviousMockHistory/PreviousMockHistory';
import CreateCourse from './components/Courses/CreateCourse';
import Dashboard from './components/Dashboard/Dashboard';

const {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  COURSES,
  COURSE_DETAILS,
  COURSE_PLAN,
  COURSE_STUDENTS,
  QNA_PAGE,
  USER_DETAILS,
  USER_ASSIGNMENT,
  MESSAGES,
  TASK_QUESTIONS,
  SLOTS,
  APPOINTMENTS,
  TEAMS,
  APPOINTMENT_REPORT,
  MOCK_HISTORY,
  DASHBOARD,
} = pathNames;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const classes = shiftStyles();
  const drawerOpen = useSelector((state) => state.common.drawerOpen);
  const isLogin = () => {
    const token = window.localStorage.getItem(WEB_TOKEN);
    if (token) {
      agent.setToken(token);

      return true;
    }
    return false;
  };
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <>
            <NavBar />

            <div
              className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerOpen,
              })}
            >
              <Component {...props} />
            </div>
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function App({ history }) {
  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              width: '40px',
              height: '40px',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <h4>Loading...</h4>
            <CircularProgress />
          </div>
        }
      >
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={Login} exact />
            <Route path={LOGIN} component={Login} exact />
            <Route path={FORGOT_PASSWORD} component={ForgotPassword} exact />
            <Route path={`${RESET_PASSWORD}`} component={ResetPassword} exact />
            <PrivateRoute
              path={USER_ASSIGNMENT}
              component={AssignmentSubmission}
              exact
            />
            <PrivateRoute path={USER_DETAILS} component={UserDetails} exact />
            <PrivateRoute path={COURSES} component={Courses} exact />
            <PrivateRoute path={DASHBOARD} component={Dashboard} exact />
            <PrivateRoute path={COURSE_PLAN} component={CoursePlan} exact />
            <PrivateRoute
              path={COURSE_DETAILS}
              component={CreateCourse}
              exact
            />
            <PrivateRoute path={SLOTS} component={Slots} exact />
            <PrivateRoute path={APPOINTMENTS} component={Appointments} exact />
            <PrivateRoute
              path={COURSE_STUDENTS}
              component={CourseStudents}
              exact
            />
            <PrivateRoute
              path={TASK_QUESTIONS}
              component={TaskQuestions}
              exact
            />
            <PrivateRoute path={MESSAGES} component={UserMessages} exact />
            <PrivateRoute path={QNA_PAGE} component={Questions} exact />
            <PrivateRoute path={TEAMS} component={Teams} exact />
            <PrivateRoute
              path={APPOINTMENT_REPORT}
              component={AppointmentReport}
              exact
            />
            <PrivateRoute
              path={MOCK_HISTORY}
              component={PreviousMockHistory}
              exact
            />
            <Route
              path="*"
              component={() => (
                <h1 className="center tc">404 Page not found</h1>
              )}
              exact
            />
          </Switch>
        </ConnectedRouter>
      </React.Suspense>
      <ToastContainer />
    </>
  );
}
export default App;
