export const pathNames = {
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password/:token',
  FORGOT_PASSWORD: '/forgot-password',
  REGISTER: '/register',
  COURSES: '/courses',

  CREATE_COURSE: '/create-courses',
  COURSE_PLAN: '/course-plan',
  COURSE_STUDENTS: '/course-students',
  COURSE_DETAILS: '/course-details',
  QNA_PAGE: '/questions',
  COLLEGES: '/colleges',
  MESSAGES: '/message',
  USERS: '/users',
  USER_DETAILS: '/user',
  USER_ASSIGNMENT: '/user/:userId/assignment/:assignmentId',
  TASK_QUESTIONS: '/task/questions',
  SLOTS: '/slots',
  APPOINTMENTS: '/appointments',
  TEAMS: '/teams',
  APPOINTMENT_REPORT: '/appointment-report/:appointmentId',
  MOCK_HISTORY: '/mock-history/:userId',
};
